import { request } from '../request';
import {
  CUSTOM_FEED_WIDGET_ID,
  POST_LIST_WIDGET_ID,
  CUSTOM_FEED_LAYOUT_IDS,
  POST_LIST_WIDGET_LAYOUT_IDS,
  RECENT_POSTS_WIDGET_ID,
  CUSTOM_FEED_TOKEN,
} from './constants';

const TOP_METADATA_HEIGHT = 45;
const BOTTOM_METADATA_HEIGHT = 45;

const fetchAppSettings = async (compId) => {
  const appSettingsResponse = await request(
    `/_api/communities-blog-node-api/_api/settings?compId=${compId}&viewMode=editor`,
  );
  const appSettings = await appSettingsResponse.json();

  const appSettingStyles = {
    ...mapSettingsToStyleParams(appSettings?.style?.numbers, 'number'),
    ...mapSettingsToStyleParams(appSettings?.style?.booleans, 'boolean'),
    ...mapColorsToStyleParams(appSettings?.style?.colors),
    ...mapSettingsToStyleParams(appSettings?.style?.fonts, 'font', JSON.stringify),
  };
  const categoryId = appSettings?.custom?.['widget-categoryId'];

  return { appSettingStyles, categoryId };
};

const mapColorsToStyleParams = (colors) => {
  if (!colors) {
    return {};
  }

  return Object.keys(colors).reduce((result, color) => {
    const rgbValue = colors[color].value;
    const alpha = rgbValue.replace(')', '').split(',')[3];
    result[`param_color_${color}`] = rgbValue;
    result[`alpha-param_color_${color}`] = alpha;
    return result;
  }, {});
};

const mapSettingsToStyleParams = (styles, prefix, parser) => {
  if (!styles) {
    return;
  }

  return Object.keys(styles).reduce((result, style) => {
    result[`param_${prefix}_${style}`] = parser ? parser(styles[style]) : styles[style];
    return result;
  }, {});
};

export const getCustomFeedComponents = async (sdk) => {
  const componentRefs = await sdk.document.components.getAllComponents('');
  const allComponents = await Promise.all(
    componentRefs.map((comp) => sdk.document.components.serialize(CUSTOM_FEED_TOKEN, { componentRef: comp })),
  );
  const components = allComponents.filter(
    (comp) =>
      comp &&
      comp.componentType === 'wysiwyg.viewer.components.tpapps.TPAWidget' &&
      comp.data &&
      comp.data.widgetId === CUSTOM_FEED_WIDGET_ID,
  );

  return Promise.all(
    components.map(async (comp) => {
      const styleClone = {
        ...comp.style,
        style: { ...comp.style.style },
      };
      let appSettings = {};
      try {
        appSettings = await fetchAppSettings(comp.originCompId);
      } catch (e) {}

      const { appSettingStyles, categoryId } = appSettings;
      const { properties, propertiesSource } = getDefaultStyles(appSettingStyles);

      if (!comp.style.style) {
        styleClone.skin = 'wysiwyg.viewer.skins.TPAWidgetSkin';
        styleClone.style = {
          groups: {},
          properties,
          propertiesSource,
        };
      }

      styleClone.style.properties = { ...properties, ...(comp?.style?.style?.properties || {}) };
      styleClone.style.propertiesSource = { ...propertiesSource, ...(comp?.style?.style?.propertiesSource || {}) };
      return {
        customFeed: { ...comp, style: styleClone },
        categoryId,
      };
    }),
  );
};

export const getRecentPostsComponents = async (sdk) => {
  const componentRefs = await sdk.document.components.getAllComponents('');
  const allComponents = await Promise.all(
    componentRefs.map((comp) => sdk.document.components.serialize(CUSTOM_FEED_TOKEN, { componentRef: comp })),
  );
  return allComponents
    .filter(
      (comp) =>
        comp &&
        comp.componentType === 'wysiwyg.viewer.components.tpapps.TPAWidget' &&
        comp.data &&
        comp.data.widgetId === RECENT_POSTS_WIDGET_ID,
    )
    .map((recentPosts) => {
      if (!recentPosts.style || !recentPosts.style.style) {
        recentPosts.style = {
          componentClassName: 'wysiwyg.viewer.components.tpapps.TPAWidget',
          metaData: {
            isHidden: false,
            isPreset: false,
            schemaVersion: '1.0',
            ...recentPosts.metaData,
          },
          skin: 'wysiwyg.viewer.skins.TPAWidgetSkin',
          styleType: 'custom',
          style: {},
          type: 'ComponentStyle',
        };
      }

      const defaultStyles = getDefaultRecentPostsStyles();
      const styleClone = {
        ...recentPosts.style,
        style: { ...recentPosts.style.style },
      };
      styleClone.style.properties = { ...defaultStyles.properties, ...(recentPosts?.style?.style?.properties || {}) };
      styleClone.style.propertiesSource = {
        ...defaultStyles.propertiesSource,
        ...(recentPosts?.style?.style?.propertiesSource || {}),
      };

      return { ...recentPosts, style: styleClone };
    });
};

export const updateComponent = async (sdk, componentToReplace) => {
  if (!componentToReplace.layout.docked) {
    await sdk.document.components.layout.update(CUSTOM_FEED_TOKEN, {
      componentRef: { id: componentToReplace.originCompId, type: 'DESKTOP' },
      layout: { ...componentToReplace.layout },
    });
  }

  await sdk.document.components.data.update(CUSTOM_FEED_TOKEN, {
    componentRef: { id: componentToReplace.originCompId, type: 'DESKTOP' },
    data: { ...componentToReplace.data, widgetId: POST_LIST_WIDGET_ID },
  });

  await sdk.document.components.style.update(CUSTOM_FEED_TOKEN, {
    componentRef: { id: componentToReplace.originCompId, type: 'DESKTOP' },
    style: componentToReplace.style.style.properties,
  });
};

export const layoutMapper = (value) =>
  ({
    [CUSTOM_FEED_LAYOUT_IDS.minimal]: POST_LIST_WIDGET_LAYOUT_IDS.editorial,
    [CUSTOM_FEED_LAYOUT_IDS.magazine]: POST_LIST_WIDGET_LAYOUT_IDS.magazine,
    [CUSTOM_FEED_LAYOUT_IDS.oneColumn]: POST_LIST_WIDGET_LAYOUT_IDS.oneColumn,
    [CUSTOM_FEED_LAYOUT_IDS.sideBySide]: POST_LIST_WIDGET_LAYOUT_IDS.sideBySide,
    [CUSTOM_FEED_LAYOUT_IDS.postcard]: POST_LIST_WIDGET_LAYOUT_IDS.magazine,
  }[value]);

export const mapContentHeight = (customFeed, defaultHeight = '101') => () => {
  const styleProps = customFeed?.style?.style?.properties;
  const isViewCountEnabled =
    styleProps?.['param_boolean_blog-isViewCountEnabled'] === 'true' ||
    styleProps?.['param_boolean_blog-isViewCountEnabled'] === true;
  const isCommentCountEnabled =
    styleProps?.['param_boolean_blog-isCommentCountEnabled'] === 'true' ||
    styleProps?.['param_boolean_blog-isCommentCountEnabled'] === true;
  const isLikeCountEnabled =
    styleProps?.['param_boolean_blog-isLikeCountEnabled'] === 'true' ||
    styleProps?.['param_boolean_blog-isLikeCountEnabled'] === true;
  const isMetadataVisible = isViewCountEnabled || isCommentCountEnabled || isLikeCountEnabled;

  if (isMetadataVisible) {
    return `${Number(defaultHeight) + 45}`;
  }

  return defaultHeight;
};

export const getDefaultStyles = (appSettings = {}) => {
  const appSettingsSources = Object.keys(appSettings).reduce((result, setting) => {
    result[setting] = 'value';
    return result;
  }, {});
  const defaults = {
    'param_boolean_blog-isAuthorNameEnabled': 'true',
    'param_boolean_blog-isPostPublishDateEnabled': 'true',
    'param_boolean_blog-isReadingTimeEnabled': 'true',
    'param_boolean_blog-isViewCountEnabled': 'true',
    'param_boolean_blog-isCommentCountEnabled': 'true',
    'param_boolean_blog-isLikeCountEnabled': 'true',
    'param_number_list-postLimitType': '1',
    'param_number_list-numberOfPosts': '2',
    'param_number_list-numberOfRows': '2',
    'param_number_list-layoutId': CUSTOM_FEED_LAYOUT_IDS.sideBySide,
    ...appSettings,
  };
  const defaultSources = {
    'param_boolean_blog-isAuthorNameEnabled': 'value',
    'param_boolean_blog-isPostPublishDateEnabled': 'value',
    'param_boolean_blog-isReadingTimeEnabled': 'value',
    'param_boolean_blog-isViewCountEnabled': 'value',
    'param_boolean_blog-isCommentCountEnabled': 'value',
    'param_boolean_blog-isLikeCountEnabled': 'value',
    'param_number_list-postLimitType': 'value',
    'param_number_list-numberOfPosts': 'value',
    'param_number_list-numberOfRows': 'value',
    'param_number_list-layoutId': 'value',
    ...appSettingsSources,
  };
  return {
    properties: defaults,
    propertiesSource: defaultSources,
  };
};

const getDefaultRecentPostsStyles = () => {
  const defaults = {
    'param_boolean_blog-isAuthorNameEnabled': 'false',
    'param_boolean_blog-isPostPublishDateEnabled': 'false',
    'param_boolean_blog-isReadingTimeEnabled': 'false',
    'param_boolean_blog-isViewCountEnabled': 'true',
    'param_boolean_blog-isCommentCountEnabled': 'true',
    'param_boolean_blog-isLikeCountEnabled': 'true',
    'param_number_list-postLimitType': '1',
    'param_number_list-numberOfPosts': '3',
    'param_number_list-numberOfRows': '1',
    'param_font_post-titleFont':
      '{"family":"open sans","displayName":"Paragraph 2","style":{"bold":false,"italic":false,"underline":false},"size":16,"preset":"Custom","editorKey":"font_8","fontStyleParam":true}',
    'param_number_list-layoutId': POST_LIST_WIDGET_LAYOUT_IDS.editorial,
  };
  const defaultSources = {
    'param_boolean_blog-isAuthorNameEnabled': 'value',
    'param_boolean_blog-isPostPublishDateEnabled': 'value',
    'param_boolean_blog-isReadingTimeEnabled': 'value',
    'param_boolean_blog-isViewCountEnabled': 'value',
    'param_boolean_blog-isCommentCountEnabled': 'value',
    'param_boolean_blog-isLikeCountEnabled': 'value',
    'param_number_list-postLimitType': 'value',
    'param_number_list-numberOfPosts': 'value',
    'param_number_list-numberOfRows': 'value',
    'param_number_list-layoutId': 'value',
  };
  return {
    properties: defaults,
    propertiesSource: defaultSources,
    groups: {},
  };
};

export const mapPostsPerPage = (customFeed) => () => {
  const styleProps = customFeed?.style?.style?.properties;
  const isPaginationEnabled = Number(styleProps['param_number_list-postLimitType']) === 1;
  const postsPerRow = styleProps['param_number_list-numberOfPosts'];

  if (!postsPerRow || !isPaginationEnabled) {
    return '50';
  }

  return postsPerRow;
};

export const mapPostsPerRow = (customFeed, postsPerRow = 3) => () => {
  const styleProps = customFeed?.style?.style?.properties;
  const isPaginationEnabled = Number(styleProps['param_number_list-postLimitType']) === 1;
  const numberOfRows = styleProps['param_number_list-numberOfRows'];

  if (!numberOfRows || !isPaginationEnabled) {
    return '50';
  }

  return `${Number(numberOfRows * postsPerRow)}`;
};

export const mapMargins = (customFeed) => () => {
  const width = customFeed.layout.width;

  if (Number(width) >= 980) {
    return '20';
  }

  return '0';
};

const getClosestImageRatio = (imageRatio, imageRatioMap, imageRatioValues) => {
  const values = Object.keys(imageRatioValues);
  const closestValue = values.reduce((prev, curr) =>
    Math.abs(Number(curr) - imageRatio) < Math.abs(Number(prev) - imageRatio) ? Number(curr) : Number(prev),
  );
  return imageRatioMap[imageRatioValues[closestValue]] || imageRatioMap['1'];
};

export const mapOneColumnImageRatio = (customFeed) => () => {
  const imageRatioMap = {
    '16:9': '1',
    '4:3': '2',
    '1:1': '3',
    '3:4': '4',
    '9:16': '5',
  };
  const imageRatioValues = {
    1.78: '16:9',
    1.33: '4:3',
    1: '1:1',
    0.75: '3:4',
    0.56: '9:16',
  };
  const width = Number(customFeed.layout.width);
  const height = 400;
  const imageRatio = width / height;
  return getClosestImageRatio(imageRatio, imageRatioMap, imageRatioValues);
};

export const mapSideBySideImageRatio = (customFeed) => () => {
  const imageRatioMap = {
    '4:3': '2',
    '1:1': '3',
    '3:4': '4',
  };
  const imageRatioValues = {
    1.33: '4:3',
    1: '1:1',
    0.75: '3:4',
  };

  const styleProps = customFeed?.style?.style?.properties;
  const isPaginationEnabled = Number(styleProps['param_number_list-postLimitType']) === 1;

  if (!isPaginationEnabled) {
    return imageRatioMap['4:3'];
  }

  const numberOfPosts = styleProps['param_number_list-numberOfPosts'];
  const layoutHeight = Number(customFeed.layout.height);
  const margins = Number(numberOfPosts) * 32;

  const height = (layoutHeight - margins) / numberOfPosts;
  const width = Number(customFeed.layout.width) / 2;
  const imageRatio = width / height;
  return getClosestImageRatio(imageRatio, imageRatioMap, imageRatioValues);
};

export const mapOneColumnContentHeight = (customFeed, { defaultHeight, isMobile } = { defaultHeight: 218 }) => () => {
  const styleProps = customFeed?.style?.style?.properties;
  const isTopMetadataVisible =
    String(styleProps?.[`param_boolean_blog-${isMobile ? 'mobile-' : ''}isAuthorNameEnabled`]) === 'true' ||
    String(styleProps?.[`param_boolean_blog-${isMobile ? 'mobile-' : ''}isPostPublishDateEnabled`]) === 'true' ||
    String(styleProps?.[`param_boolean_blog-${isMobile ? 'mobile-' : ''}isReadingTimeEnabled`]) === 'true';
  const isBottomMetadataVisible =
    String(styleProps?.[`param_boolean_blog-${isMobile ? 'mobile-' : ''}isViewCountEnabled`]) === 'true' ||
    String(styleProps?.[`param_boolean_blog-${isMobile ? 'mobile-' : ''}isCommentCountEnabled`]) === 'true' ||
    String(styleProps?.[`param_boolean_blog-${isMobile ? 'mobile-' : ''}isLikeCountEnabled`]) === 'true';

  if (isTopMetadataVisible && isBottomMetadataVisible) {
    return Number(defaultHeight) + TOP_METADATA_HEIGHT + BOTTOM_METADATA_HEIGHT;
  } else if (isTopMetadataVisible) {
    return Number(defaultHeight) + TOP_METADATA_HEIGHT;
  } else if (isBottomMetadataVisible) {
    return Number(defaultHeight) + BOTTOM_METADATA_HEIGHT;
  }

  return defaultHeight;
};

export const fetchCategories = () => {
  return request('/_api/communities-blog-node-api/_api/categories?offset=0&size=500');
};

export const getCategoryStableId = (categories, categoryId) => {
  const assignedCategory = categories.find((category) => category._id === categoryId);

  if (!assignedCategory) {
    return categoryId;
  }

  return assignedCategory.id;
};

export const calculateTOILayoutWidth = (columnWidth = 292, fullLayoutWidth) => {
  const margin = fullLayoutWidth >= 980 ? 32 : 16;
  const numberOfColumns = Math.floor(fullLayoutWidth / (columnWidth + margin));
  const containerPadding = 8;
  return numberOfColumns * (columnWidth + margin) + containerPadding;
};
